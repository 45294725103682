import { Component, Input } from "@angular/core";

interface Square {
  color?: string;
  width: string;
  height: string;
}

@Component({
  template: `<div [ngStyle]="{ 'background-color': data.color, width: data.width, height: data.height }"></div> `,
})
export class SquareComponent {
  private _data: Square = {
    height: "20px",
    width: "20px",
  };

  get data() {
    return this._data;
  }

  @Input("data") set data(value: Square) {
    this._data = {
      ...this._data,
      ...value,
    };
  }
}
