import * as infschemas from "@infiot/infschemas";
import { PreferredExitProps } from "@policy/policy-details/policy-details-form";
import {
  DestinationPtdType,
  HOP_SITE_KEY_FOR_SIG_IPSEC,
  PBR_NEXT_HOP_SITE_CLOUD_HUB,
  getGenericIPSecName,
} from "@policy/policy-details/policy-details-topology/topology-destinations/topology-destinations.helper";

export interface PreferredExit {
  id: string;
  name: string;
  hop: string;
  type: string;
  groupId?: string;
}

export function getPreferredExit(data: {
  ipsecList: infschemas.DestinationConfigGenericIpsec[];
  hubList: infschemas.NexthopSettings[];
  hubGroupList: infschemas.NexthopSettings[];
  hopSite: string;
  hop: string;
  hopGroupId: string;
}): PreferredExit | undefined {
  const { ipsecList, hubList, hubGroupList, hopSite, hop, hopGroupId } = data;
  let preferredExit: PreferredExit | undefined;

  if (
    [
      HOP_SITE_KEY_FOR_SIG_IPSEC,
      DestinationPtdType.SIG_GRE,
      DestinationPtdType.NETSKOPE,
      PBR_NEXT_HOP_SITE_CLOUD_HUB,
    ].includes(hopSite)
  ) {
    const ipSecDetail = ipsecList.find((ipsec) => ipsec?.id === hop);

    if (ipSecDetail) {
      preferredExit = {
        id: ipSecDetail.id!,
        name: getGenericIPSecName(ipSecDetail)!,
        hop,
        type: ipSecDetail.ptd_type!,
      };
    }
  } else if (hopGroupId) {
    const group = hubGroupList.find((h) => h.group_id === hopGroupId);

    if (group) {
      preferredExit = {
        id: group.group_id!,
        name: group.group_name!,
        hop,
        type: group.ptd_type!,
        groupId: group.group_id,
      };
    }
  } else {
    // for HUB
    const hubDetail = hubList.find((h) => h.ovlay_name === hopSite && h.ovlay_ipv4 === hop);

    if (hubDetail) {
      preferredExit = {
        id: hubDetail.ovlay_id,
        name: hopSite,
        hop,
        type: hubDetail.ptd_type!,
      };
    }
  }

  return preferredExit;
}

export function getHopSite(preferredExit: PreferredExit, hubGroupList: infschemas.NexthopSettings[] = []) {
  switch (preferredExit.type) {
    case DestinationPtdType.HUB:
      return preferredExit.name;
    case DestinationPtdType.HUB_GROUP: {
      const group = hubGroupList.find((g) => g.ovlay_name);

      return group?.ovlay_name;
    }
    case DestinationPtdType.NEWEDGE:
      return PBR_NEXT_HOP_SITE_CLOUD_HUB;
    case DestinationPtdType.SIG_IPSEC:
    case DestinationPtdType.NETSKOPE:
      return HOP_SITE_KEY_FOR_SIG_IPSEC;
    default:
      return preferredExit.type;
  }
}

export function getHopDetails(preferredExit: PreferredExit, hubGroupList: infschemas.NexthopSettings[] = []) {
  return {
    [PreferredExitProps.HOP_SITE]: getHopSite(preferredExit, hubGroupList),
    [PreferredExitProps.HOP]: preferredExit.hop,
    [PreferredExitProps.HOP_GROUP_ID]: preferredExit.groupId ?? "",
  };
}

export function getPreferredExitIndex(preferredExits: PreferredExit[], preferredExit: PreferredExit) {
  return preferredExits.findIndex((p) => {
    return preferredExit.type === infschemas.DestinationPtdType.HUB_GROUP
      ? p.groupId === preferredExit.groupId
      : !p.groupId && p.id === preferredExit.id;
  });
}

export function filterDestinationsByPreferredExits(
  destinations: {
    hubList: infschemas.NexthopSettings[];
    hubGroupList: infschemas.NexthopSettings[];
    ipsecList: infschemas.GenericIpsec[];
  },
  preferredExits: PreferredExit[],
) {
  let hubList: infschemas.NexthopSettings[] = [];
  let hubGroupList: infschemas.NexthopSettings[] = [];
  let ipsecList: infschemas.GenericIpsec[] = [];

  if (destinations.hubList.length > 0) {
    const set = new Set(preferredExits.filter((p) => p.type === DestinationPtdType.HUB).map((p) => p.id));
    hubList = destinations.hubList.filter((h) => !set.has(h.ovlay_id));
  }

  if (destinations.hubGroupList.length > 0) {
    const set = new Set(preferredExits.filter((p) => p.type === DestinationPtdType.HUB_GROUP).map((p) => p.groupId));
    hubGroupList = destinations.hubGroupList.filter((h) => !set.has(h.group_id));
  }

  if (destinations.ipsecList.length > 0) {
    const set = new Set(
      preferredExits
        .filter((p) => p.type !== DestinationPtdType.HUB && p.type !== DestinationPtdType.HUB_GROUP)
        .map((p) => p.id),
    );
    ipsecList = destinations.ipsecList.filter((h) => !set.has(h.id!));
  }

  return {
    hubList,
    hubGroupList,
    ipsecList,
  };
}
