import infgqlDeviceIntelligence from "@infiot-fuse/interfaces/mgmtsvc/device-intelligence.types";

export const DefaultDeviceRiskType = "all";

export enum DeviceRiskType {
  HIGH = "high",
  MEDIUM = "medium",
  LOW = "low",
  NEGLIGIBLE = "negligible",
  UNKNOWN = "unknown",
}

export function filterDeviceRiskCounts(
  deviceRiskData: { data: infgqlDeviceIntelligence.DeviceIntelligenceGroupByCount[]; total: number },
  selectedDeviceRisk: DeviceRiskType | typeof DefaultDeviceRiskType,
) {
  const riskCount = {
    high: 0,
    medium: 0,
    low: 0,
    negligible: 0,
    unknown: 0,
  };

  if (deviceRiskData.data.length > 0) {
    deviceRiskData.data.forEach((risk) => {
      if (selectedDeviceRisk !== DefaultDeviceRiskType) {
        if (risk.name === selectedDeviceRisk) {
          riskCount[risk.name] = risk.count;
        }
      } else {
        riskCount[risk.name] = risk.count;
      }
    });
  }

  const deviceCount =
    selectedDeviceRisk !== DefaultDeviceRiskType ? riskCount[selectedDeviceRisk] : deviceRiskData.total;

  return {
    deviceCount,
    riskCount,
  };
}
