<mat-card *ngIf="lteForm$ | async" class="darken-background">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>{{ "IDS_SITE_CONFIGURABLES_LTE" | translate }}</mat-panel-title>
    </mat-expansion-panel-header>
    <div fxLayout="column" fxLayoutGap="{{ infGlobalSvc.gutterMd }}">
      <div>
        <mat-divider [inset]="true"></mat-divider>
      </div>
      <div [formGroup]="lteForm">
        <div *ngIf="lteCount > 1" fxLayout="column" fxLayoutGap="{{ infGlobalSvc.gutterMd }}" class="lte-config">
          <mat-slide-toggle
            fxFlexAlign="start"
            [checked]="lteForm?.value.lte_sim_slot == 0"
            labelPosition="before"
            color="primary"
            (change)="priorityToggle($event)"
          >
            {{ "IDS_SITE_INTERFACE_PRIMARY" | translate }}
          </mat-slide-toggle>
        </div>
        <div fxLayout="column" fxLayoutGap="{{ infGlobalSvc.gutterMd }}" class="lte-config">
          <div fxLayout="row" fxLayoutGap="{{ infGlobalSvc.gutterMd }}">
            <div
              *ngFor="let lteMode of lteModes; let ii = index"
              id="intfLteMode{{ ii }}"
              [ngClass]="lteForm.value.lte_config_mode == lteMode.name ? 'lte-active' : 'lte-default'"
              fxLayout="column"
              fxLayoutAlign="space-evenly center"
              (click)="selectLteMode(lteMode.name)"
              class="lte-selector"
            >
              <inf-icon class="lte-icon">{{ lteMode.icon }}</inf-icon>
              <div class="lte-label">{{ lteMode.label | translate }}</div>
            </div>
          </div>
          <div
            fxLayout="row"
            fxLayoutGap="{{ infGlobalSvc.gutterMd }}"
            fxLayoutGap.gt-sm="{{ infGlobalSvc.gutterLg }}"
            [formGroup]="lteForm"
          >
            <mat-form-field>
              <mat-label>{{ "IDS_SITE_CONFIGURABLES_APN" | translate }}</mat-label>
              <input
                matInput
                [templateVariable]="{ enabled: isTemplateService }"
                id="intfLteApn"
                formControlName="lte_apn"
                [required]="lteForm.value.lte_config_mode == lteModeEnum.manual"
              />
              <mat-error infError="lte_apn"></mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ "IDS_SITE_CONFIGURABLES_USERNAME" | translate }}</mat-label>
              <input
                matInput
                id="intfLteUsername"
                formControlName="lte_user_name"
                [required]="lteForm.value.lte_config_mode == lteModeEnum.manual"
              />
              <mat-error infError="lte_user_name"></mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ "IDS_SITE_CONFIG_PASSWORD" | translate }}</mat-label>
              <input
                matInput
                id="intfLtePassword"
                formControlName="lte_password"
                [required]="lteForm.value.lte_config_mode == lteModeEnum.manual"
              />
              <mat-error infError="lte_password"></mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <mat-hint>
        {{ "IDS_ASTERISK_EXPLANATION" | translate }}
      </mat-hint>
      <div></div>
    </div>
  </mat-expansion-panel>
</mat-card>
