import { PasswordInputComponent } from "@infiot/ngx-shared-lib";
import { Component, forwardRef, Injector, Optional, Input, inject } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "inf-password-template-input",
  template: `
    <mat-form-field>
      <mat-label>{{ label | translate }}</mat-label>
      <input
        matInput
        id="{{ id }}-input"
        [errorStateMatcher]="errorStateMatcher"
        [formControl]="formControl"
        [value]="value || ''"
        (change)="setValueAndEmit($event.target.value)"
        [required]="required"
        [type]="showPassword ? 'text' : 'password'"
        [templateVariable]="templateVariableRef"
      />
      <mat-icon matIconSuffix id="{{ id }}-eye-icon" (click)="showPassword = !showPassword">{{
        showPassword ? "visibility" : "visibility_off"
      }}</mat-icon>
      <inf-help-icon matIconSuffix [content]="tooltip"></inf-help-icon>
      <mat-error [infError]="formControl"></mat-error>
    </mat-form-field>
  `,
  providers: [
    { provide: PasswordInputComponent, useExisting: InfPasswordTemplateInputComponent },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordInputComponent),
      multi: true,
    },
  ],
})
export class InfPasswordTemplateInputComponent extends PasswordInputComponent {
  @Optional()
  protected injector = inject(Injector);

  protected translate = inject(TranslateService);

  /**
   * templateVariableRef
   * this variables needs to be differetn from the directive, otherwise the
   * directive will be applied to both the outer element and the inner element
   * */
  @Input() templateVariableRef;
}
