import { Subscription } from "rxjs";

import { Input, Component, ViewChild, ElementRef, QueryList, ContentChildren } from "@angular/core";

import { MapInfoWindow, GoogleMap } from "@angular/google-maps";

export interface latLng {
  latitude: number;
  longitude: number;
}

export interface bounds {
  x: latLng; // relative adjustment mathematics
  y: latLng; // relative adjustment mathematics
}

export interface latLngPlus {
  latitude: number;
  longitude: number;
  bounds?: bounds;
}

@Component({
  selector: "map-overlay",
  template: '<div #content><div style="position:absolute"><ng-content></ng-content></div></div>',
})
export class MapOverlay {
  @Input() latitude: number;

  @Input() longitude: number;

  @Input() visible: boolean = true;

  @Input() zIndex: number = 1;

  @Input() bounds: bounds;

  @Input() openInfoWindow: boolean = true;

  @ContentChildren(MapInfoWindow) infoWindow: QueryList<MapInfoWindow> = new QueryList<MapInfoWindow>();

  @ViewChild("content", { read: ElementRef }) template: ElementRef;

  destroyed: boolean;

  overlayView: any;

  // elmGuts:any
  private _observableSubscriptions: Subscription[] = [];

  constructor(protected _googleMap: GoogleMap) {}

  ngAfterViewInit() {
    this.load();
    this.onChanges = this.onChangesOverride;
  }

  ngAfterContentInit() {
    this.infoWindow.changes.subscribe(() => this.handleInfoWindowUpdate());
  }

  ngOnChanges(changes) {
    this.onChanges(changes);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChanges(changes) {}

  onChangesOverride(changes) {
    if (changes.latitude || changes.longitude || changes.zIndex) {
      this.overlayView.latitude = this.latitude;
      this.overlayView.longitude = this.longitude;
      this.overlayView.zIndex = this.zIndex;
      this.destroy();
      this.load();
    }
  }

  ngOnDestroy() {
    this.destroy();
  }

  destroy() {
    this.destroyed = true;

    if (this.overlayView) {
      if (this.overlayView.div) {
        this.overlayView.remove();
      }
      this.overlayView.setMap(null);
    }

    this._observableSubscriptions.forEach((s) => s.unsubscribe());

    delete this.overlayView;
  }

  private handleInfoWindowUpdate() {
    if (this.infoWindow.length > 1) {
      throw new Error("Expected no more than one info window.");
    }

    // TODO: check this
    /* this.infoWindow.forEach((iWin) => {
      iWin.hostMarker = <any>this.overlayView;
    }); */
  }

  load() {
    this.getOverlay(this._googleMap.googleMap).then((overlay) => {
      overlay.setMap(this._googleMap.googleMap || null);
    });
  }

  async getOverlayInstance() {
    const maps: any = await google.maps.importLibrary("maps");

    return new maps.OverlayView();
  }

  async getOverlay(map): Promise<google.maps.OverlayView> {
    this.overlayView = this.overlayView || (await this.getOverlayInstance());

    /* make into foo marker that AGM likes */
    this.overlayView.iconUrl = " ";
    this.overlayView.latitude = this.latitude;
    this.overlayView.longitude = this.longitude;
    this.overlayView.visible = false; // hide 40x40 transparent placeholder that prevents hover events
    /* end */

    if (this.bounds) {
      this.overlayView.bounds_ = new google.maps.LatLngBounds(
        new google.maps.LatLng(this.latitude + this.bounds.x.latitude, this.longitude + this.bounds.x.longitude),
        new google.maps.LatLng(this.latitude + this.bounds.y.latitude, this.longitude + this.bounds.y.longitude),
      );
    }

    // js-marker-clusterer does not support updating positions. We are forced to delete/add and compensate for .removeChild calls
    const elm = this.template.nativeElement.children[0];
    // const elm =  this.elmGuts || this.template.nativeElement.children[0]

    // we must always be sure to steal our stolen element back incase we are just in middle of changes and will redraw
    const restore = (div) => {
      this.template.nativeElement.appendChild(div);
    };

    this.overlayView.remove = function (): void {
      if (!this.div) {
        return;
      }
      this.div.parentNode.removeChild(this.div);
      restore(this.div);
      delete this.div;
    };

    this.overlayView.getDiv = function () {
      return this.div;
    };

    this.overlayView.draw = function () {
      if (!this.div) {
        this.div = elm;
        const panes = this.getPanes();
        // if no panes then assumed not on map
        if (!panes || !panes.overlayImage) {
          return;
        }

        panes.overlayImage.appendChild(elm);
      }

      const latlng = new google.maps.LatLng(this.latitude, this.longitude);

      const proj = this.getProjection();
      if (!proj) {
        return;
      }

      const point = proj.fromLatLngToDivPixel(latlng);

      if (point) {
        elm.style.left = `${point.x - 10}px`;
        elm.style.top = `${point.y - 20}px`;
      }

      if (this.bounds_) {
        // stretch content between two points leftbottom and righttop and resize
        const proj = this.getProjection();
        const sw = proj.fromLatLngToDivPixel(this.bounds_.getSouthWest());
        const ne = proj.fromLatLngToDivPixel(this.bounds_.getNorthEast());

        this.div.style.left = `${sw.x}px`;
        this.div.style.top = `${ne.y}px`;
        this.div.children[0].style.width = `${ne.x - sw.x}px`;
        this.div.children[0].style.height = `${sw.y - ne.y}px`;
      }
    };

    elm.addEventListener("click", (event) => {
      this.handleTap();
      event.stopPropagation();
    });

    this.handleInfoWindowUpdate();

    return this.overlayView;
  }

  handleTap() {
    if (this.openInfoWindow) {
      this.infoWindow.forEach((infoWindow) => {
        infoWindow.open();
      });
    }
  }
}
