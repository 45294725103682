import * as inflsa from "@infiot-fuse/services/inf-local-storage-auth";

/**
 * Download and save file as CSV
 * @param targetUrl - target url for CSV file
 * @param fileName - Downloaded file name
 */
export const exportAsCSV = async (
  targetUrl: string,
  fileName: string,
  queryParams: Record<string, any> = new URLSearchParams(),
) => {
  queryParams.append("format", "csv");
  queryParams.append("token", inflsa.getToken());

  const queryParamsStr = queryParams.toString();
  const targetExportURL = `${targetUrl}?${queryParamsStr}`;

  const response = await fetch(targetExportURL, {
    headers: {
      "Content-Type": "text/csv",
    },
  });
  const csvBlob = await response.blob();
  const csvFileObjectURL = URL.createObjectURL(csvBlob);

  const link: HTMLAnchorElement = document.createElement("a");
  link.href = csvFileObjectURL;
  link.download = fileName;
  link.click();
};
