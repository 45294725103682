import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FuseSharedModule } from "@fuse/shared.module";
import { InfDirectivesModule } from "@infiot-fuse/directives/inf-directives.module";
import { TranslateModule } from "@ngx-translate/core";
import { AngularMaterialModule } from "@infiot-fuse/modules/angular-material.module";
import { MultiSelectInputModule } from "@infiot/ngx-shared-lib";
import { VersionWarningModule } from "app/main/warning/warning.module";
import { InfCardChartModule } from "../card-chart/inf-card-chart.module";
import { InfCardHeaderModule } from "../card-header/inf-card-header.module";
import { InfCardTableModule } from "../card-table/inf-card-table.module";
import { InfChoiceSelectorModule } from "../choice-selector/inf-choice-selector.module";
import { InfConfirmationDialogModule } from "../confirmation-dialog/inf-confirmation-dialog.module";
import { InfCreatedModifiedInfoModule } from "../created-modified-info/inf-created-modified-info.module";
import { InfDataInfoRowModule } from "../data-info-row/inf-data-info-row.module";
import { InfHeaderModule } from "../header/inf-header.module";
import { InfHelpIconModule } from "../help-icon/inf-help-icon.module";
import { InfIconModule } from "../icon/inf-icon.module";
import { InfStickyFabModule } from "../sticky-fab/inf-sticky-fab.module";
import { ClientAddressingComponent } from "./client-addressing/client-addressing.component";
import { HighAvailabilityComponent } from "./high-availability/high-availability.component";
import { LteInterfaceComponent } from "./lte-interface/lte-interface.component";
import { WifiWanInterfaceComponent } from "./wifiwan-interface/wifiwan-interface.component";
import { SiteInterfaceComponent } from "./site-interface/site-interface.component";
import { OspfSettingsComponent } from "./ospf-settings/ospf-settings.component";

@NgModule({
  imports: [
    AngularMaterialModule,
    CommonModule,
    FuseSharedModule,
    InfDataInfoRowModule,
    InfCardHeaderModule,
    InfCardChartModule,
    InfCardTableModule,
    InfChoiceSelectorModule,
    InfConfirmationDialogModule,
    InfCreatedModifiedInfoModule,
    InfDirectivesModule,
    InfHeaderModule,
    InfHelpIconModule,
    InfIconModule,
    InfStickyFabModule,
    RouterModule,
    TranslateModule,
    MultiSelectInputModule,
    VersionWarningModule,
  ],
  exports: [
    ClientAddressingComponent,
    SiteInterfaceComponent,
    LteInterfaceComponent,
    HighAvailabilityComponent,
    OspfSettingsComponent,
    WifiWanInterfaceComponent,
  ],
  declarations: [
    ClientAddressingComponent,
    LteInterfaceComponent,
    HighAvailabilityComponent,
    OspfSettingsComponent,
    SiteInterfaceComponent,
    WifiWanInterfaceComponent,
  ],
  providers: [],
})
export class SiteInterfaceConfigureModule {}
