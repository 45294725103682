import { UntypedFormControl, ValidatorFn } from "@angular/forms";

export function imsUrlValidator(allowedDomains): ValidatorFn {
  return (control: UntypedFormControl) => {
    if (!control.value) {
      return null;
    }

    const errors: Record<string, any> = {};
    try {
      const { domain, isAllowed } = isDomainAllowed(control.value, allowedDomains);
      if (!isAllowed) {
        errors.IOT_INVALID_DOMAIN = {
          domain,
        };
      }
    } catch {
      return null;
    }

    if (Object.keys(errors).length === 0) {
      return null;
    } else {
      return errors;
    }
  };
}

export function isDomainAllowed(text: string, allowedDomains: string[]) {
  const url = new URL(text);
  let domain = url.hostname;
  const domainArr = domain.split(".");

  // excluding subdomain
  if (domainArr.length > 0 && domainArr.length !== 2) {
    domain = `${domainArr[domainArr.length - 2]}.${domainArr[domainArr.length - 1]}`;
  }

  return {
    domain,
    isAllowed: allowedDomains.includes(domain),
  };
}
