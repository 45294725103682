import { Component, inject, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { InfGlobalSvc } from "@infiot-fuse/services/inf-global.service";
import { TranslateService } from "@ngx-translate/core";
import { InfVpnPeersService, VpnPeerMonitor, VpnPeerTypeTransKey } from "@infiot-fuse/services/inf-vpn-peers.service";
import { VpnPeersUIService } from "../vpn-peers-ui.service";
import { getDisplayLocation } from "../vpn-peers.utils";

@Component({
  templateUrl: "./vpn-peer-monitor.component.html",
})
export class VpnPeersMonitorComponent implements OnInit {
  infGlobalSvc = inject(InfGlobalSvc);

  vpnPeersService = inject(InfVpnPeersService);

  activatedRoute = inject(ActivatedRoute);

  router = inject(Router);

  translate = inject(TranslateService);

  id: string;

  headerTitle: string;

  loading = true;

  isMonitoringDataAvailable = true;

  public overviewPath = VpnPeersUIService.MonitorOverviewScreenPath;

  public peersPath = VpnPeersUIService.MonitorPeersScreenPath;

  constructor() {
    this.id = this.activatedRoute.snapshot.params["id"];
  }

  async ngOnInit() {
    this.loading = true;

    // TODO: integrate telemetry api
    const vpnPeer = await this.vpnPeersService.getOneObject(this.id).toPromise();

    if (vpnPeer) {
      this.headerTitle = `${this.translate.instant("IDS_VPN_PEERS_MONITOR_TITLE")} ${vpnPeer.name}`;

      const vpnPeerMonitor: VpnPeerMonitor = {
        ...vpnPeer,
        typeLabel: VpnPeerTypeTransKey[vpnPeer.type],
        displayLocation: getDisplayLocation(vpnPeer.location),
      };

      this.vpnPeersService.setMonitorData(vpnPeerMonitor);

      this.isMonitoringDataAvailable = true;
    } else {
      this.isMonitoringDataAvailable = false;
    }

    this.loading = false;
  }

  navigateToEditVpnGateway() {
    void this.router.navigateByUrl(
      `${VpnPeersUIService.CoreComponentPath}/${VpnPeersUIService.editScreenPath(this.id)}`,
    );
  }
}
