<ng-container *ngIf="!item.hidden">
  <!-- item.url -->
  <a
    class="nav-link"
    *ngIf="item.url && !item.externalUrl && !item.function && !item.isActive"
    [routerLink]="[item.url]"
    [ngClass]="item.classes + (isActive(fuseNavigationService.routerLinkChanged | async) ? ' active' : '')"
    [target]="item.openInNewTab ? '_blank' : '_self'"
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- item.externalUrl -->
  <a
    class="nav-link"
    [ngClass]="item.classes"
    *ngIf="item.url && item.externalUrl && !item.function && !item.isActive"
    [href]="item.url"
    [target]="item.openInNewTab ? '_blank' : '_self'"
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- item.function -->
  <span class="nav-link" [ngClass]="item.classes" *ngIf="!item.url && item.function" (click)="item.function(this)">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </span>

  <!-- item.url && item.function -->
  <a
    class="nav-link"
    *ngIf="item.url && !item.externalUrl && item.function && !item.isActive"
    (click)="item.function(this)"
    [routerLink]="[item.url]"
    [ngClass]="item.classes + (isActive(fuseNavigationService.routerLinkChanged | async) ? 'active' : '')"
    [target]="item.openInNewTab ? '_blank' : '_self'"
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- item.externalUrl && item.function -->
  <a
    class="nav-link"
    [ngClass]="item.classes"
    *ngIf="item.url && item.externalUrl && item.function && !item.isActive"
    (click)="item.function(this)"
    [href]="item.url"
    [target]="item.openInNewTab ? '_blank' : '_self'"
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- item.url && item.isActive -->
  <a
    class="nav-link"
    *ngIf="item.url && !item.externalUrl && !item.function && item.isActive"
    [routerLink]="[item.url]"
    [ngClass]="item.classes + (item.isActive(item, fuseNavigationService.routerLinkChanged | async) ? ' active' : '')"
    [target]="item.openInNewTab ? '_blank' : '_self'"
  >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <ng-template #itemContent>
    <mat-icon [title]="item.title" class="nav-link-icon" *ngIf="item.icon">{{ item.icon }}</mat-icon>
    <span class="nav-link-title" [translate]="item.translate">
      {{ item.title }}
      <feature-label *ngIf="item.featureLabel" type="{{ item.featureLabel }}"></feature-label>
    </span>
    <span
      class="nav-link-badge"
      *ngIf="item.badge"
      [translate]="item.badge.translate"
      [ngStyle]="{ 'background-color': item.badge.bg, color: item.badge.fg }"
    >
      {{ item.badge.title }}
    </span>
  </ng-template>
</ng-container>
