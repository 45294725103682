<ng-container *ngIf="!showApp">
  <!-- Placeholder for when user is not logged in -->
  <div style="background-color: #fafafa; width: 100%; height: 100%"></div>
</ng-container>

<ng-container *ngIf="showApp">
  <fuse-progress-bar></fuse-progress-bar>

  <!-- VERTICAL LAYOUT 1 -->
  <ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-1'">
    <vertical-layout-1></vertical-layout-1>
  </ng-container>
</ng-container>

<mat-menu #helpPopup hasBackdrop="false" class="help-popup-menu">
  <ng-template matMenuContent let-popupContent="popupContent" let-popupTemplate="popupTemplate">
    <div [innerHtml]="popupContent"></div>
    <div *ngTemplateOutlet="popupTemplate"></div>
  </ng-template>
</mat-menu>

<popup></popup>
