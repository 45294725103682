import * as rxjs from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SiteInterfaceService {
  ospfDisabledSubject: rxjs.Subject<boolean> = new rxjs.Subject();

  emitOspfSettingChange(setting: boolean) {
    this.ospfDisabledSubject.next(setting);
  }
}
