import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { ReactWrapperComponent } from "./react-wrapper.component";
import { MicroFloatingUI } from "./micro-floating-ui.directive";

@NgModule({
  declarations: [ReactWrapperComponent, MicroFloatingUI],
  exports: [ReactWrapperComponent, MicroFloatingUI],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ReactWrapperModule {}
