<mat-dialog-content style="padding: unset">
  <div class="dialog dialog-wrapper">
    <div class="dialog__header" fxLayout="row" fxLayoutAlign="space-between center">
      <h2>{{ "IDS_SITE_CONFIG_CLOUD_ACCOUNT" | translate }}</h2>
      <button class="dialog__header--button" (click)="closeDialog()">
        <mat-icon class="dialog__header--icon"> close </mat-icon>
      </button>
    </div>
    <div class="dialog__body" fxLayout="column" fxLayoutAlign="space-between center">
      <mat-icon class="dialog__body--user-detail-img"> person </mat-icon>
      <span class="dialog__body--user-detail-email">
        {{ userEmail }}
      </span>
    </div>
    <div class="dialog__footer" fxLayout="column" fxLayoutAlign="space-between">
      <div class="dialog__footer--action-row" fxLayout="row" fxLayoutAlign="start center">
        <a
          fxLayout="row"
          fxLayoutAlign="start center"
          class="dialog__footer--actions-link"
          href="https://www.netskope.com/privacy-policy"
          target="_blank"
        >
          <mat-icon class="dialog__footer--actions-icon">lock</mat-icon>
          <span>{{ "IDS_PRIVACY_POLICY" | translate }}</span>
        </a>
      </div>
      <div class="dialog__footer--action-row" fxLayout="row" fxLayoutAlign="start center">
        <button fxLayout="row" fxLayoutAlign="start center" class="dialog__footer--actions-button" (click)="logout()">
          <mat-icon class="dialog__footer--actions-icon"> exit_to_app </mat-icon>
          <span>{{ "IDS_FUSE_TOOLBAR_EVENTS_LOGOUT" | translate }}</span>
        </button>
      </div>
      <div
        class="dialog__footer--action-row"
        fxLayout="row"
        fxLayoutAlign="end"
        *ngIf="version"
        title="{{ internalVersion }}"
        style="margin-top: 16px"
      >
        <span style="padding: 0 8px">{{ version }}</span>
      </div>
    </div>
  </div>
</mat-dialog-content>
