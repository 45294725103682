import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatRippleModule } from "@angular/material/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { MatDialogModule } from "@angular/material/dialog";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatTooltipModule } from "@angular/material/tooltip";

import { FeatureLabelModule } from "@infiot/ngx-shared-lib";
import { InfIconModule } from "@infiot-fuse/components/icon/inf-icon.module";

import { FuseNavVerticalFooterComponent } from "./navigation-footer.component";
import { InfAccountDialogComponent } from "../account-dialog/account-dialog.component";

@NgModule({
  imports: [
    CommonModule,
    FeatureLabelModule,
    RouterModule,
    MatIconModule,
    MatRippleModule,
    TranslateModule.forChild(),
    InfIconModule,
    MatDialogModule,
    FlexLayoutModule,
    MatTooltipModule,
  ],
  exports: [FuseNavVerticalFooterComponent],
  declarations: [FuseNavVerticalFooterComponent, InfAccountDialogComponent],
})
export class FuseNavigationFooterModule {}
