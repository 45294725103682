import { Component, HostListener, Inject, OnDestroy, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Platform } from "@angular/cdk/platform";
import { TranslateService } from "@ngx-translate/core";
import { MatMenu } from "@angular/material/menu";
import * as rxjs from "rxjs";
import * as rxjsoperators from "rxjs/operators";

import { FuseConfigService } from "@fuse/services/config.service";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";

import { nagivation, systemNavigation, userNavigation } from "app/navigation/navigation";
import { AuthService } from "@infiot-fuse/services/auth.service";
import { InfSettingsSvc } from "@infiot-fuse/services/inf-settings.service";
import { InfThemeService } from "@infiot-fuse/services/inf-theme.service";
import { InfTitleSvc } from "@infiot-fuse/services/inf-title.service";
import { InfInactivitySvc } from "@infiot-fuse/services/inactivity.service";
import { InfPopupService } from "@infiot-fuse/services/inf-popup.service";
import { InfTerminalSvc } from "@infiot-fuse/services/inf-terminal-service";
import infmgmtintf from "@infiot/infmgmtintf";
import { roleUser } from "@infiot/infauth/dist/auth.types";
import { TranslateUiService } from "@infiot/ngx-shared-lib";

@Component({
  selector: "app",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  fuseConfig: any;

  showApp: boolean;

  @ViewChild("helpPopup", { static: false }) helpPopup: MatMenu;

  // Private
  private _unsubscribeAll: rxjs.Subject<any>;

  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseNavigationService} _fuseNavigationService
   * @param {Platform} _platform
   * @param {TranslateService} _translateService
   * @param {AuthService} _authService
   * @param {InfSettingsSvc} _infSettingsSvc
   * @param {InfThemeService} _infThemeService
   */
  constructor(
    @Inject(DOCUMENT) private _document: any,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _platform: Platform,
    private _translateService: TranslateService,
    private _authService: AuthService,
    private _infSettingsSvc: InfSettingsSvc,
    private _infThemeService: InfThemeService,
    private _inactivityService: InfInactivitySvc,
    private _titleSvc: InfTitleSvc,
    public popupService: InfPopupService,
    private _infTerminalSvc: InfTerminalSvc,
    private translateUiService: TranslateUiService, // private _featureFlagService: FeatureFlagService,
  ) {
    // Register the navigation to the service
    this._fuseNavigationService.register("main", nagivation);
    this._fuseNavigationService.register("system", systemNavigation);
    this._fuseNavigationService.register("user", userNavigation);

    // Handle auth immediately
    this.showApp = false;
    this._infSettingsSvc.getInfSettings().subscribe((settings) => {
      this.showApp =
        !this._infSettingsSvc.isInitialSettings &&
        (!this._authService.pathNeedsLogin() || this._infSettingsSvc.loggedIn());
      this._infThemeService.setTheme(InfThemeService.CurrentTheme);
      let nav = "main";
      if (settings.fes_tenantCapabilities?.includes(infmgmtintf.TenantCapability.HasTenants)) {
        nav = "system";
      } else if (settings.fes_user?.fes_roles.includes(roleUser.name)) {
        nav = "user";
      }

      // Set the main navigation as our current navigation
      this._fuseNavigationService.setCurrentNavigation(nav);
    });

    // Add languages
    // this._translateService.addLangs(['en', 'tr']);

    // Set the default language
    this._translateService.setDefaultLang("en");

    this._translateService.use("en").subscribe(() => {
      this.translateUiService.init("en");
    });

    this._titleSvc.init();

    /**
     * ----------------------------------------------------------------------------------------------------
     * ngxTranslate Fix Start
     * ----------------------------------------------------------------------------------------------------
     */

    /**
     * If you are using a language other than the default one, i.e. Turkish in this case,
     * you may encounter an issue where some of the components are not actually being
     * translated when your app first initialized.
     *
     * This is related to ngxTranslate module and below there is a temporary fix while we
     * are moving the multi language implementation over to the Angular's core language
     * service.
     */

    // Set the default language to 'en' and then back to 'tr'.
    // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
    // been selected and there is no way to force it, so we overcome the issue by switching
    // the default language back and forth.
    /**
     * setTimeout(() => {
     * this._translateService.setDefaultLang('en');
     * this._translateService.setDefaultLang('tr');
     * });
     */

    /**
     * ----------------------------------------------------------------------------------------------------
     * ngxTranslate Fix End
     * ----------------------------------------------------------------------------------------------------
     */

    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      this._document.body.classList.add("is-mobile");
    }

    // Set the private defaults
    this._unsubscribeAll = new rxjs.Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._fuseConfigService.config.pipe(rxjsoperators.takeUntil(this._unsubscribeAll)).subscribe((config) => {
      this.fuseConfig = config;

      // Color theme - Use normal for loop for IE11 compatibility
      for (let i = 0; i < this._document.body.classList.length; i++) {
        const className = this._document.body.classList[i];

        if (className.startsWith("theme-")) {
          this._document.body.classList.remove(className);
        }
      }

      this._document.body.classList.add(this.fuseConfig.colorTheme);
    });
  }

  ngAfterViewInit(): void {
    this.popupService.menu = this.helpPopup;
  }

  /**
   * On destroy
   * This is never called in practice right now PK Nov 19
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  @HostListener("window:mousemove", ["$event"]) onmousemove(event) {
    this._inactivityService.handleMouseMove();
  }

  @HostListener("window:keypress", ["$event"]) onkeypress(event) {
    this._inactivityService.handleKeyPress();
  }

  @HostListener("window:click", ["$event"]) onclick(event) {
    this._inactivityService.handleClicked();
  }

  @HostListener("window:beforeunload", ["$event"])
  beforeunloadHandler(event) {
    this._infTerminalSvc.ngOnDestroy();
  }
}
