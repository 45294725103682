import { InputOption } from "@common/interfaces";
import { Site } from "@infiot/mgmtsvc2-types";

export interface ActiveSite {
  id: string;
  name: string;
}

export function getUniqueSites(sites: Site[], existingSites: InputOption<string>[]) {
  if (sites.length === 0) {
    return [];
  }

  const searchedList = sites.map((site) => {
    return {
      optionValue: site.id!,
      optionLabel: site.name!,
    };
  });

  if (searchedList?.length > 0) {
    const siteSet = new Set(existingSites.map((g) => g.optionValue));

    return searchedList.filter((site) => !siteSet.has(site.optionValue));
  }

  return [];
}

export function compareSiteSelection(standbySites: string[], activeSites: ActiveSite[]) {
  if (activeSites.length > 0 && standbySites.length > 0) {
    const set: Set<string> = new Set(standbySites);

    for (const site of activeSites) {
      if (set.has(site.id)) {
        return {
          site: {
            duplicate: true,
            name: site.name,
          },
        };
      }
    }
  }

  return null;
}

export function getOrderedSitesBySiteIds(siteInputOptions: InputOption<string>[], siteIds: string[]) {
  if (siteIds.length === 0) {
    return [];
  }

  const set: Set<string> = new Set(siteIds);
  const orderedActiveSites: ActiveSite[] = [];

  siteInputOptions.forEach((op) => {
    if (set.has(op.optionValue)) {
      orderedActiveSites.push({
        id: op.optionValue,
        name: op.optionLabel,
      });
    }
  });

  return orderedActiveSites;
}

export function deleteActiveSite(site: ActiveSite, selectedSiteIds: string[], activeSites: ActiveSite[]) {
  return {
    selectedSiteIds: selectedSiteIds.filter((s) => s !== site.id),
    activeSites: activeSites.filter((s) => s.id !== site.id),
  };
}
