export type Feature = {
  readonly name: string;
  readonly tenantLabel?: string;
};

// This is to enable a feature by default -- it no longer needs to be present
// in the feature flags to be in shipped state.  Ideally once something is no
// long subject to feature flags we should remove the feature flag from the code
// this is just a temporary placeholder for that process.
const shippedFeatures = {
  ADDRESS_GROUP: true,
  ADVANCE_DEVICE_SECURITY: true,
  CLIENTS: true,
  CUSTOM_APPS: true,
  HOME: true,
  IOT_MANAGER: true,
  MONITOR_APPLICATION_STATS: true,
  MONITOR_DEVICE_STATS: true,
  MONITOR_PROCESS_STATS: true,
  MONITOR_TRANSACTIONS_STATS: true,
  RADIUS: true,
  SECURITY_FIREWALL: true,
  SEGMENTATION: true,
  SITE_HA: true,
  ZTNA: true,
  ZTP: true,
};

// All features in the system
export const features = [
  // Shipped - here for reference
  { name: "ADDRESS_GROUP" },
  { name: "ADVANCE_DEVICE_SECURITY" },
  { name: "CLIENTS" },
  { name: "CUSTOM_APPS" },
  { name: "HOME" },
  { name: "IOT_MANAGER" },
  { name: "MONITOR_APPLICATION_STATS" },
  { name: "MONITOR_DEVICE_STATS" },
  { name: "MONITOR_PROCESS_STATS" },
  { name: "MONITOR_TRANSACTIONS_STATS" },
  { name: "RADIUS" },
  { name: "SECURITY_FIREWALL" },
  {
    name: "SEGMENTATION",
    tenantLabel: "enable-multi-vrf-segmentation",
  },
  { name: "SITE_HA" },
  { name: "ZTNA" },
  { name: "ZTP" },

  // Still partially deployed
  { name: "ADDRESS_GROUP_APPX_AND_FIREWALL_DESTINATION" },
  { name: "APP_ACCESS_POLICIES" },
  { name: "POLICY_DESTINATION_SIG_GRE" },
  { name: "POLICY_DESTINATION_NEW_EDGE_CLOUD_HUB" },
  { name: "SUBINTERFACE" },
  { name: "SEGMENT_SELECTOR" },
  { name: "SASE_CLOUD_SECURITY" },
  { name: "DEMOS" },
  { name: "IP_SLA" },
  { name: "MTHUB_AUTO" },
  { name: "CLIENT_SEGMENT_SELECTOR" },
  { name: "APPX_CHARTS" },
  { name: "SKOPEIT" },
  { name: "MONITOR_ANOMALIES" },
  { name: "VPN_PEERS" },
  { name: "BACKOFFICE_AUDIT_EVENTS" },
  { name: "BACKOFFICE_GATEWAY_DASHBOARD" },
  /**
   * For SinglePane  of Glass UI.
   */
  { name: "SINGLE_UI" },
] as const;

type FeaturesFrom<F extends readonly Feature[]> = F[number]["name"];

type FeatureFlagMapFrom<F extends readonly Feature[]> = Partial<Record<FeaturesFrom<F>, boolean>>;

export function generateFlagsMap<F extends Feature, FS extends readonly F[]>(
  tenantLabels: string[],
  hiddenFeatures: string[],
  allFeatures: FS,
) {
  return Object.fromEntries(
    allFeatures.map((feature) => {
      if (tenantLabels.includes(`DISABLE-${feature.name}`)) {
        return [feature.name, false];
      } else if (
        (feature.tenantLabel && tenantLabels.includes(feature.tenantLabel)) ||
        tenantLabels.includes(feature.name)
      ) {
        return [feature.name, true];
      } else if (tenantLabels.includes(`ENABLE-${feature.name}`)) {
        return [feature.name, true];
      } else if (hiddenFeatures.includes(feature.name)) {
        return [feature.name, false];
      } else {
        return [feature.name, shippedFeatures[feature.name] ?? false];
      }
    }),
  ) as FeatureFlagMapFrom<FS>;
}

// Convenience types

export type Features = FeaturesFrom<typeof features>;

export type FeatureFlagMap = FeatureFlagMapFrom<typeof features>;
