import { Component } from "@angular/core";

@Component({
  selector: "content",
  /**
   * *ngIf="true" hack is required here for router-outlet to work
   * correctly. Otherwise, it won't register the changes on the
   * layout and won't update the view.
   */
  template: `<router-outlet *ngIf="true"></router-outlet>`,
  styles: [
    `
      :host {
        position: relative;
        display: flex;
        z-index: 1;
        flex: 1 0 auto;
      }
      :host ::ng-deep > router-outlet + * {
        display: flex;
        flex: 1 0 100%;
        max-width: 100%;
        flex-direction: column;
        align-items: stretch;
      }
    `,
  ],
  // encapsulation: ViewEncapsulation.None
})
export class ContentComponent {
  /**
   * Constructor
   */
  constructor() {}
}
