import * as infschemas from "@infiot/infschemas";

export function getHighAvailabilityEnabled(selectedProtocol: infschemas.IntfProto, overlayFormEnabled: boolean) {
  if ([infschemas.IntfProto.dhcp_relay, infschemas.IntfProto.dhcp_server].includes(selectedProtocol)) {
    return true;
  }

  if ([infschemas.IntfProto.dhcp, infschemas.IntfProto.static].includes(selectedProtocol) && !overlayFormEnabled) {
    return true;
  }

  return false;
}
