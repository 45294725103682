<ng-container>
  <div class="nav-footer-container netskope-gray">
    <a href="https://netskope.document360.io/" target="_blank">
      <mat-icon [title]="'IDS_FUSE_NAV_HELP' | translate" class="nav-footer-container__label-icon"> help </mat-icon>
      <span class="nav-footer-container__label">
        {{ "IDS_FUSE_NAV_HELP" | translate }}
      </span>
      <mat-icon class="nav-footer-container__link-icon">open_in_new</mat-icon>
    </a>

    <button (click)="openAccountDialog()">
      <mat-icon class="nav-footer-container__label-icon"> person </mat-icon>
      <span class="nav-footer-container__label">
        {{ "IDS_FUSE_NAV_ACCOUNT" | translate }}
      </span>
    </button>

    <button
      matTooltipPosition="left"
      (click)="checkForNewVersions()"
      id="infCheckForNewVersionsButtonId"
      *ngIf="canCheckForNewVersions | async"
      [matTooltip]="'IDS_FUSE_TOOLBAR_EVENTS_READ_VERSION_TIP' | translate"
      matTooltipPosition="left"
    >
      <mat-icon class="nav-footer-container__label-icon">replay</mat-icon>
      <span class="nav-footer-container__label">
        {{ "IDS_FUSE_TOOLBAR_EVENTS_READ_VERSION" | translate }}
      </span>
    </button>
  </div>
</ng-container>
