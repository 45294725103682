export function verifySegmentAllocation(interfaces, vnets) {
  // list all used segment ids from the interfaces
  let invalidAllocation = false;
  let zeroValue = false;
  const segmentIdsUsed = [...new Set(interfaces.map(({ intf_segment_id }) => intf_segment_id))];
  const segmentsInGateway = segmentIdsUsed.map((id: string) => vnets.find((vnet) => vnet.vnet_id === parseInt(id, 10)));
  if (segmentsInGateway.length === 1) {
    // we force the allocation to 100, if only one segment
    return {
      invalidAllocation: false,
      zeroValue: false,
    };
  }
  const zeroSegment = segmentsInGateway.find((segment) => (segment?.vnet_min_gu_bandwidth ?? 0) === 0);
  if (zeroSegment) {
    zeroValue = true;
    // fail quick and stop doing additional checks

    return {
      invalidAllocation,
      zeroValue,
    };
  }

  if (segmentIdsUsed.length !== vnets.length) {
    invalidAllocation = true;

    return {
      invalidAllocation,
      zeroValue,
    };
  }

  let totalAllocation = 100;
  segmentsInGateway.forEach((segment) => {
    totalAllocation -= segment?.vnet_min_gu_bandwidth ?? 0;
  });
  // because javascript is really good at subtracting float values
  if (Math.abs(totalAllocation) > 0.01) {
    invalidAllocation = true;
  }

  return {
    invalidAllocation,
    zeroValue,
  };
}
