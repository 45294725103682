import _ from "lodash";
import { Site, PathStat, LinkStat } from "@infiot/mgmtsvc2-types";
import * as infgqldashboard from "@infiot-fuse/interfaces/mgmtsvc/dashboard.types";
import * as infgqlsite from "@infiot-fuse/interfaces/mgmtsvc/site.types";
import { DcfgRole, OverlayDcfgRole, OverlayRampStatus } from "@infiot/infschemas";
import infmgmtintf from "@infiot/infmgmtintf";

const NETSKOPE_OVERLAY_ISP = "Netskope";

export interface ISiteState {
  id: string;
  state: string;
}

// In map, we show path details from both side, source -> dest and dest -> source
// When only one site detail is loaded from side nav, then there is possibilities that,
// some overlay details are not loaded, so these are loaded by separate query and passed it here.
export const formDashboardStats = (
  sites: Site[],
  siteState: ISiteState[],
  overlays?: Site[],
): infgqldashboard.SiteStats[] => {
  const sitesWithState = new Map<string, ISiteState>();
  siteState.forEach((state) => sitesWithState.set(state.id, state));

  return (
    sites?.map((site) => {
      const overlayMap = createPathMap(site.pathStats || [], overlays);

      return {
        site_id: site.id!,
        site_name: site.name!,
        location: [site.city, site.country].filter((val) => val).join(", "),
        first_online_time: site.uptimeStats?.firstTimeOnline ? new Date(site.uptimeStats.firstTimeOnline) : undefined,
        up_time: site.uptimeStats?.upTime,
        bw_total_bytes: (site.linksStats || []).reduce(
          (totalBytes: number, link: LinkStat) => link.totalBytesDelta + totalBytes,
          0,
        ),
        status: sitesWithState.get(site.id!)?.state ?? infgqlsite.SiteCompoundStatus.PENDING,
        role: _.toLower(site.role) as DcfgRole,
        hw_model: site.hardwareModelId,
        hw_model_name: site.hardwareModel,
        dcfg_sw_version: site.softwareVersion,
        score: Number(
          infmgmtintf.precisionCalculator(
            (site.linksStats || []).reduce((score: number, link: LinkStat) => Math.max(link.appXScore || 0, score), 0),
            1,
            false,
          ),
        ),
        coordinates: site.coordinates,
        linkStats: formatLinkStats(site.linksStats ?? []),
        paths: formatSiteOverlays(site.id!, sitesWithState, overlayMap),
        isPresentInDB: true,
      };
    }) ?? []
  );
};

export const getNotLoadedOverlayIds = (sites: Site[]): string[] => {
  const allLoadedSites = new Set<string>();
  const allOverlayIds = new Set<string>();
  sites.forEach((site) => {
    site.pathStats
      ?.filter((overlay) => !overlay.remoteISP.includes(NETSKOPE_OVERLAY_ISP))
      .forEach((overlay) => {
        allOverlayIds.add(overlay.id);
        allLoadedSites.add(overlay.siteId);
      });
  });

  return [...allOverlayIds].filter((overlayId) => !allLoadedSites.has(overlayId));
};

const createPathMap = (paths: PathStat[], overlays?: Site[]): Map<string, PathStat[]> => {
  const result = new Map();
  paths.forEach((overlay) => {
    const overlays = result.get(overlay.siteId);
    if (overlays) {
      overlays.push(overlay);
    } else {
      result.set(overlay.siteId, [overlay]);
    }
  });
  overlays?.forEach((site) => {
    if (!result.has(site.id)) {
      result.set(site.id, site.pathStats || []);
    }
  });

  return result;
};

const formatSiteOverlays = (
  siteId: string,
  sites: Map<string, ISiteState>,
  overlaysMap: Map<string, PathStat[]>,
): infgqldashboard.SiteOverlayStats[] => {
  const result: infgqldashboard.SiteOverlayStats[] = formatSourceToDestPathStats(siteId, overlaysMap, sites);
  if (result?.length) {
    formatDestToSourcePathStats(siteId, result, overlaysMap);
  }

  return result;
};

const formatSourceToDestPathStats = (
  siteId: string,
  overlaysMap: Map<string, PathStat[]>,
  sites: Map<string, ISiteState>,
): infgqldashboard.SiteOverlayStats[] => {
  const paths = overlaysMap.get(siteId);
  const result: infgqldashboard.SiteOverlayStats[] = [];
  for (const overlay of paths || []) {
    const overlayAdded = result.find((o) => o.id === overlay.id);
    const score = Number(infmgmtintf.precisionCalculator(overlay.appXScore || 0, 1, false));
    const beforeScore = Number(infmgmtintf.precisionCalculator(overlay.appXBeforeScore || 0, 1, false));
    const afterScore = Number(infmgmtintf.precisionCalculator(overlay.appXAfterScore || 0, 1, false));
    if (overlayAdded) {
      overlayAdded.sourceToDestStats.score = Math.max(overlayAdded.sourceToDestStats.score, score);
      overlayAdded.sourceToDestStats.isps.push({
        local_isp: overlay.localISP,
        remote_isp: overlay.remoteISP,
        before_score: beforeScore,
        after_score: afterScore,
      });
      overlayAdded.score = Math.min(overlayAdded.score, overlayAdded.sourceToDestStats.score);
    } else {
      result.push({
        id: overlay.id,
        name: overlay.name,
        role: _.toLower(overlay.role) as OverlayDcfgRole,
        coordinates: overlay.coordinates,
        score,
        status: sites.get(overlay.id)?.state ?? infgqlsite.SiteCompoundStatus.PENDING,
        sourceToDestStats: {
          score,
          isps: [
            {
              local_isp: overlay.localISP,
              remote_isp: overlay.remoteISP,
              before_score: beforeScore,
              after_score: afterScore,
            },
          ],
        },
        isPresentInDB: sites.has(overlay.id),
        rampStatus: overlay.isLocalUp && overlay.isRemoteUp ? OverlayRampStatus.UP : OverlayRampStatus.DOWN,
        dspsIntervalBytes: overlay.totalBytesDelta,
      });
    }
  }

  return result;
};

const formatDestToSourcePathStats = (
  sourceSiteId: string,
  paths: infgqldashboard.SiteOverlayStats[],
  overlaysMap: Map<string, PathStat[]>,
): void => {
  (paths || []).forEach((path) => {
    (overlaysMap.get(path.id) || [])
      .filter((overlay) => sourceSiteId === overlay.id)
      .forEach((overlay) => {
        const score = Number(infmgmtintf.precisionCalculator(overlay.appXScore || 0, 1, false));
        const beforeScore = Number(infmgmtintf.precisionCalculator(overlay.appXBeforeScore || 0, 1, false));
        const afterScore = Number(infmgmtintf.precisionCalculator(overlay.appXAfterScore || 0, 1, false));
        if (path.destToSourceStats) {
          path.destToSourceStats.score = Math.max(path.destToSourceStats.score, score);
        } else {
          path.destToSourceStats = {
            score,
            isps: [
              {
                local_isp: overlay.localISP,
                remote_isp: overlay.remoteISP,
                before_score: beforeScore,
                after_score: afterScore,
              },
            ],
          };
        }
        path.score = Math.min(path.score, path.destToSourceStats.score);
      });
  });
};

const formatLinkStats = (links: LinkStat[]): infgqldashboard.SiteWANLinksStats[] => {
  return (links || []).map((link) => ({
    ip: link.ip,
    isp: link.isp,
    status: link.status,
    latest_rxbandwidth: link.rxCapacity,
    latest_txbandwidth: link.txCapacity,
    score: Number(infmgmtintf.precisionCalculator(link.appXScore, 1, false)),
  }));
};
