import { Component, HostBinding, inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Apollo } from "apollo-angular";
import { gql } from "@apollo/client/core";

import { InfPermissionService } from "@infiot-fuse/services/inf-permission.service";
import { InfNotificationService } from "@infiot-fuse/services/inf-notification.service";
import infauth from "@infiot/infauth";
import infmgmtintf from "@infiot/infmgmtintf";
import { InfAccountDialogComponent } from "../account-dialog/account-dialog.component";

@Component({
  selector: "fuse-nav-vertical-footer",
  templateUrl: "./navigation-footer.component.html",
  styleUrls: ["./navigation-footer.component.scss"],
})
export class FuseNavVerticalFooterComponent {
  @HostBinding("class")
  classes = "nav-footer-container";

  private _infPermissionService = inject(InfPermissionService);
  private _apollo = inject(Apollo);
  private _notificationService = inject(InfNotificationService);

  canCheckForNewVersions = this._infPermissionService.every({
    requestedPrivs: [infauth.privSysWrite],
    requestedCaps: [infmgmtintf.TenantCapability.IsRootMSP],
  });

  constructor(private _dialog: MatDialog) {}

  openAccountDialog(): void {
    this._dialog.open(InfAccountDialogComponent, {
      width: "480px",
      backdropClass: "account-dialog-backdrop",
      position: {
        top: "10%",
      },
    });
  }

  checkForNewVersions(): void {
    const checkForNewVersionsMutation = gql`
      mutation {
        checkForNewVersions
      }
    `;
    this._apollo.mutate<void>({ mutation: checkForNewVersionsMutation }).subscribe(
      (x) => {
        console.log(x);
      },
      (error) => {
        const message = error.message.replace("GraphQL error: ", "");
        this._notificationService.createErrorAlert(message);
      },
    );
  }
}
