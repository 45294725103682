import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { TranslateModule } from "@ngx-translate/core";
import { FuseSharedModule } from "@fuse/shared.module";

import { InfDirectivesModule } from "@infiot-fuse/directives/inf-directives.module";
import { SiteStatusComponent } from "./site-status.component";

@NgModule({
  imports: [CommonModule, InfDirectivesModule, TranslateModule, FuseSharedModule],
  exports: [SiteStatusComponent],
  declarations: [SiteStatusComponent],
  providers: [],
})
export class InfSiteStatusModule {}
