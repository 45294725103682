import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { FuseSharedModule } from "@fuse/shared.module";
import { InfDirectivesModule } from "@infiot-fuse/directives/inf-directives.module";

import { InfMiscUIService } from "./misc-ui.service";
import { LoggedoutComponent } from "./loggedout.component";

const routes = [
  {
    path: InfMiscUIService.LoggedoutPath.slice(1), // remove leading /
    component: LoggedoutComponent,
  },
];

@NgModule({
  declarations: [LoggedoutComponent],
  imports: [RouterModule.forChild(routes), FuseSharedModule, InfDirectivesModule],
})
export class MiscModule {}
