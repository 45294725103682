import { ValidatorFn, AbstractControl } from "@angular/forms";
import { NumberValidators } from "@infiot/infvalidators";

const MAX_16_INT = 65535;

function validateCommunityOrInteger(value) {
  const invalidCommunityObj = {
    validateCommunity: {
      valid: false,
    },
  };
  if (value.indexOf(":") > 0) {
    const splittedArray = value.split(":");
    const [first, second] = splittedArray;
    if (second === "" || splittedArray.length > 2) {
      return invalidCommunityObj;
    }

    return +first >= 0 && +first <= MAX_16_INT && +second <= MAX_16_INT && +second >= 0 ? null : invalidCommunityObj;
  } else {
    return !Number.isNaN(value) && +value >= 0 && +value <= MAX_16_INT
      ? NumberValidators.isInteger(+value)
      : invalidCommunityObj;
  }
}

function cleanWhiteSpaces(source: string): string {
  let str = source;
  while (str.indexOf(" ") > 0) {
    str = str.split(" ").join("");
  }

  return str;
}

const IP_DOMAIN_REGEX = /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}\/([0-9]|[12][0-9]|3[0-2])$/;

export function validateRouteMapName(): ValidatorFn {
  return (control: AbstractControl) => {
    const matchValue = `${control.value}`;
    if (matchValue.includes(" ") || matchValue.length > 100) {
      return {
        validateRoutemapName: {
          valid: false,
        },
      };
    }

    return null;
  };
}

export function validateIp(): ValidatorFn {
  return (control: AbstractControl) => {
    const matchValue = cleanWhiteSpaces(`${control.value}`);
    if (matchValue.includes(",") || matchValue.includes("/")) {
      // ips list
      const elements = matchValue.split(",");
      let isValid = true;
      if (elements.length > 10) {
        return {
          validateIPList: {
            valid: false,
          },
        };
      }
      elements.forEach((ipDomainEntry) => {
        if (!IP_DOMAIN_REGEX.test(ipDomainEntry)) {
          isValid = false;
        }
      });

      return isValid
        ? null
        : {
            validateIPList: {
              valid: false,
            },
          };
    } else if (IP_DOMAIN_REGEX.test(matchValue)) {
      return null;
    } else {
      return {
        validateIPList: {
          valid: false,
        },
      };
    }
  };
}

export function validateCommunity(): ValidatorFn {
  return (control: AbstractControl) => {
    const matchValue = cleanWhiteSpaces(`${control.value}`);
    if (matchValue.includes(",")) {
      const possibleValues = matchValue.split(",");
      if (possibleValues.includes("")) {
        return {
          validateCommunity: {
            valid: false,
          },
        };
      }
      let possibleResult;
      let count = 0;
      while (!possibleResult && possibleValues[count]) {
        possibleResult = validateCommunityOrInteger(possibleValues[count]);
        count++;
      }

      return possibleResult;
    } else {
      return validateCommunityOrInteger(matchValue);
    }
  };
}

export function validateSingleValue(min?: number, max?: number, validationError?: string): ValidatorFn {
  return (control: AbstractControl) => {
    const matchValue = cleanWhiteSpaces(`${control.value}`);
    if (max && validationError && min !== undefined) {
      return !Number.isNaN(+matchValue) && +matchValue >= min && +matchValue <= max
        ? NumberValidators.isInteger(+matchValue)
        : {
            [validationError]: {
              valid: false,
            },
          };
    }

    return NumberValidators.isInteger(+matchValue);
  };
}
