<inf-header [headerTitle]="'IDS_MANAGE_ALERTS' | translate" headerId="alertsHeaderId" />

<mat-card class="mat-elevation-z4">
  <inf-card-header [cardTitle]="'IDS_ALERTS' | translate"></inf-card-header>
  <mat-card-content>
    <inf-table-view fxFlex [rows]="alertsDataSource">
      <ng-template inf-table-view-cell="name" inf-table-view-cell-label="{{ 'IDS_ALERT_NAME' | translate }}" let-row>
        <div class="alert-field">{{ row.name }}</div>
      </ng-template>
      <ng-template
        inf-table-view-cell="method"
        inf-table-view-cell-label="{{ 'IDS_ALERT_METHOD' | translate }}"
        let-row
      >
        <div class="alert-field">{{ getTransportType(row) | translate }}</div>
      </ng-template>
      <ng-template
        inf-table-view-cell="events"
        inf-table-view-cell-label="{{ 'IDS_ALERT_ALERT_TYPE' | translate }}"
        let-row
      >
        <div class="alert-field" infEllipsize="{{ getAlertTypes(row) }}">{{ getAlertTypes(row) }}</div>
      </ng-template>
      <ng-template
        inf-table-view-cell="destination"
        inf-table-view-cell-label="{{ 'IDS_ALERT_DESTINATION' | translate }}"
        let-row
      >
        <div class="alert-field" infEllipsize="{{ getAlertDestinations(row) }}">
          {{ getAlertDestinations(row) }}
        </div>
      </ng-template>
      <ng-template
        inf-table-view-cell="enabled"
        inf-table-view-cell-label="{{ 'IDS_ALERT_ENABLED' | translate }}"
        let-row
      >
        <div>
          {{ (row.enabled ? "IDS_YES" : "IDS_NO") | translate }}
        </div>
      </ng-template>
      <ng-template
        inf-table-view-cell="actions"
        inf-table-view-cell-label="{{ 'IDS_ALERT_ACTIONS' | translate }}"
        let-row
        let-index
      >
        <button mat-icon-button [matMenuTriggerFor]="multiOptionsMenu" (click)="openActionsMenu($event)">
          <inf-icon class="options-icon">more_vert</inf-icon>
        </button>
        <mat-menu #multiOptionsMenu="matMenu">
          <button mat-menu-item (click)="editAlert(row)">
            {{ "IDS_ALERT_BUTTON_EDIT" | translate }}
          </button>
          <button mat-menu-item (click)="deleteAlert(row)">
            {{ "IDS_ALERT_BUTTON_DELETE" | translate }}
          </button>
        </mat-menu>
      </ng-template>
    </inf-table-view>
  </mat-card-content>
  <mat-card-footer>
    <inf-sticky-fab *ngIf="readyToInteract" tooltipText="IDS_ALERT_BUTTON_CREATE" (click)="createAlert()">
    </inf-sticky-fab>
  </mat-card-footer>
</mat-card>

<ng-template #alertFormComponent let-dialog="dialog">
  <h1 mat-dialog-title>
    {{ alertFormTitle | translate }}
  </h1>
  <div mat-dialog-content>
    <div fxLayout="column" fxLayoutAlign="start start" [formGroup]="alertForm">
      <!-- NAME FIELD -->
      <div class="alert-form-field">
        <text-input required id="alertFormName" formControlName="name" label="{{ 'IDS_ALERT_NAME' | translate }}">
        </text-input>
      </div>

      <!-- ALERT TYPE FIELD -->
      <div class="alert-form-field alert-type-box">
        <mat-label>{{ "IDS_ALERT_ALERT_TYPE" | translate }}</mat-label>
        <div class="alert-type-checkbox-section">
          <ng-container *ngFor="let option of alertTypeOptions">
            <checkbox-input
              *ngIf="option"
              id="alertFormTypeInput-{{ option[0] }}"
              label="{{ option[1] }}"
              class="alert-type-checkbox"
              [value]="selectedAlert?.events.includes(option[0])"
              (valueChange)="toggleAlertType($event, option[0])"
            >
            </checkbox-input>
          </ng-container>
        </div>
      </div>

      <!-- NOTIFICATION METHOD (transport_type) FIELD -->
      <div class="alert-form-field">
        <select-input
          id="alertFormTransportType"
          formControlName="transport_type"
          label="{{ 'IDS_ALERT_NOTIFICATION_METHOD' | translate }}"
          [inputOptions]="alertMethods"
        >
        </select-input>
      </div>

      <!-- TRANSPORT GROUP FIELD -->
      <div class="group" formGroupName="transport">
        <!-- WEBHOOK SHARED SECRET FIELD -->
        <div class="alert-form-field" *ngIf="alertForm!.get('transport.shared_secret')">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="{{ infGlobalSvc.gutterMd }}">
            <basic-button
              id="generateSecretKeyButton"
              [busy]="isGeneratingSecretKey"
              color="primary"
              [type]="ButtonType.Stroked"
              (click)="generateSharedSecretKey()"
            >
              {{ "IDS_ALERT_FORM_GENERATE_SHARED_SECRET_KEY_TEXT" | translate }}
            </basic-button>
            <password-input
              id="alertFormSharedSecretKeyInput"
              [showEyeIcon]="false"
              readonly="true"
              formControlName="shared_secret"
              label="{{ 'IDS_ALERT_FORM_SHARED_SECRET_KEY_TEXT' | translate }}"
            >
            </password-input>
            <mat-icon
              class="code-copy-icon"
              *ngIf="alertForm!.get('transport.shared_secret')!.value"
              (click)="copyCode(alertForm!.get('transport.shared_secret')!.value)"
              >file_copy</mat-icon
            >
          </div>
        </div>

        <!-- WEBHOOK URL FIELD -->
        <div class="alert-form-field" *ngIf="alertForm!.get('transport.url')">
          <div>
            <textarea-input
              id="alertFormWebhookUrlInput"
              formControlName="url"
              class="test"
              label="{{ 'IDS_ALERT_WEBHOOK_URL' | translate }}"
              (valueChange)="onLinkChange(alertForm!.get('transport')!)"
            >
            </textarea-input>
          </div>
          <div
            class="textarea-link-test-section"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="{{ infGlobalSvc.gutterMd }}"
          >
            <basic-button
              id="verifyWebhookUrlButton"
              [busy]="alertForm!.get('transport.verification_status')!.value === VERIFICATION_STATUS.pending"
              [disabled]="!alertForm!.get('transport.url')!.valid || !alertForm!.get('transport.shared_secret')!.valid"
              color="primary"
              [type]="ButtonType.Stroked"
              (click)="verifyWebhookUrl(alertForm!.get('transport')!)"
            >
              {{ "IDS_ALERT_FORM_TEST_TEXT" | translate }}
            </basic-button>
            <span
              class="test-result-section"
              *ngIf="alertForm!.get('transport.verification_status')!.value === VERIFICATION_STATUS.verified"
            >
              <inf-icon class="alert-test-success-icon">svg:check</inf-icon>
              <span class="test-result-text">{{ "IDS_ALERT_FORM_TEST_SUCCESSFULLY" | translate }}</span>
            </span>
            <span
              class="test-result-section"
              *ngIf="alertForm!.get('transport.verification_status')!.value === VERIFICATION_STATUS.failed"
            >
              <inf-icon class="alert-test-fail-icon">svg:invalid</inf-icon>
              <span class="test-result-text">{{ "IDS_ALERT_FORM_TEST_FAILED" | translate }}</span>
            </span>
          </div>
        </div>

        <!-- EMAIL RECEIVERS FIELD -->
        <div class="alert-form-field" *ngIf="alertForm!.get('transport.receivers')">
          <div
            formArrayName="receivers"
            fxLayout="column"
            fxLayoutAlign="start start"
            fxLayoutGap="{{ infGlobalSvc.gutterMd }}"
          >
            <div class="section-title">{{ "IDS_ALERT_FORM_EMAILS_TEXT" | translate }}</div>
            <div
              *ngFor="let receiver of getFormArrayReceivers()['controls']; let i = index"
              formGroupName="{{ i }}"
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="{{ infGlobalSvc.gutterMd }}"
              class="emails-sections"
            >
              <text-input
                id="alertFormEmailInput-{{ i }}"
                (valueChange)="onLinkChange(receiver)"
                formControlName="email"
                label="{{ 'IDS_ALERT_FORM_ENTER_EMAIL_TEXT' | translate }}"
              >
              </text-input>
              <button
                mat-icon-button
                class="alert-delete-icon"
                *ngIf="getFormArrayReceivers().length > 1"
                (click)="_settingFormSvc.deleteEmailField(i)"
              >
                <mat-icon>delete</mat-icon>
              </button>
              <basic-button
                id="alertFormVerifyEmailButton-{{ i }}"
                [busy]="receiver.get('verification_status').value === VERIFICATION_STATUS.pending"
                [disabled]="!receiver.get('email').valid"
                color="primary"
                [type]="ButtonType.Stroked"
                (click)="receiver.get('email').valid && verifyEmail(receiver)"
              >
                {{ "IDS_ALERT_FORM_TEST_TEXT" | translate }}
              </basic-button>
              <span
                *ngIf="receiver.get('verification_status').value === VERIFICATION_STATUS.verified"
                class="test-result-section"
              >
                <inf-icon class="alert-test-success-icon">svg:check</inf-icon>
                <span class="test-result-text">{{ "IDS_ALERT_FORM_TEST_SUCCESSFULLY" | translate }}</span>
              </span>
              <span
                *ngIf="receiver.get('verification_status').value === VERIFICATION_STATUS.failed"
                class="test-result-section"
              >
                <inf-icon class="alert-test-fail-icon">svg:invalid</inf-icon>
                <span class="test-result-text">{{ "IDS_ALERT_FORM_TEST_FAILED" | translate }}</span>
              </span>
            </div>
          </div>
          <button
            mat-button
            color="accent"
            (click)="_settingFormSvc.addMoreEmailFields()"
            class="text-button"
            id="alertFormAddMoreEmailButton"
          >
            <div fxLayout="row">
              <inf-icon class="plus-icon">svg:plus</inf-icon>
              <div>
                {{ "IDS_ALERT_FORM_ADD_MORE_TEXT" | translate }}
              </div>
            </div>
          </button>
        </div>

        <!-- PAGER DUTY SECRET FIELD -->
        <div class="alert-form-field" *ngIf="alertForm!.get('transport.routing_key')">
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="{{ infGlobalSvc.gutterMd }}"
            class="emails-sections"
          >
            <text-input
              id="alertFormSecretInput"
              formControlName="routing_key"
              label="{{ 'IDS_ALERT_FORM_PAGER_DUTY_SECRET_KEY_TEXT' | translate }}"
              tooltip="{{ 'IDS_SETTINGS_TOOLTIPS.stn_pagerduty.pdty_secret' | translate }}"
            >
            </text-input>
            <basic-button
              id="verifyPagerDutyButton"
              [busy]="alertForm!.get('transport.verification_status')!.value === VERIFICATION_STATUS.pending"
              [disabled]="!alertForm!.get('transport.routing_key')!.valid"
              color="primary"
              [type]="ButtonType.Stroked"
              (click)="verifyPagerDuty(alertForm!.get('transport')!)"
            >
              {{ "IDS_ALERT_FORM_TEST_TEXT" | translate }}
            </basic-button>
            <span
              class="test-result-section"
              *ngIf="alertForm!.get('transport.verification_status')!.value === VERIFICATION_STATUS.verified"
            >
              <inf-icon class="alert-test-success-icon">svg:check</inf-icon>
              <span class="test-result-text">{{ "IDS_ALERT_FORM_TEST_SUCCESSFULLY" | translate }}</span>
            </span>
            <span
              class="test-result-section"
              *ngIf="alertForm!.get('transport.verification_status')!.value === VERIFICATION_STATUS.failed"
            >
              <inf-icon class="alert-test-fail-icon">svg:invalid</inf-icon>
              <span class="test-result-text">{{ "IDS_ALERT_FORM_TEST_FAILED" | translate }}</span>
            </span>
          </div>
        </div>
      </div>

      <!-- ENABLED FIELD -->
      <div class="alert-form-field">
        <checkbox-input
          id="alertFormEnableInput"
          formControlName="enabled"
          label="{{ 'IDS_ALERT_ENABLE_TEXT' | translate }}"
        >
        </checkbox-input>
      </div>
    </div>
  </div>
  <button-bar
    id="alertSettingsButtons"
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="end center"
    [busy]="dialog.busy || isMutationInProgress"
    [disabled]="dialog.busy"
    [saveDisabled]="!alertForm!.valid"
    (cancel)="dialog.close(false)"
    cancelButtonText="{{ 'IDS_CANCEL' | translate }}"
    saveButtonText="{{ 'IDS_SAVE' | translate }}"
    (submit)="muteAlert(dialog)"
  >
  </button-bar>
</ng-template>
