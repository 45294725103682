import { Component, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectorRef } from "@angular/core";
import * as rxjs from "rxjs";
import * as rxjsoperators from "rxjs/operators";

import { FuseConfigService } from "@fuse/services/config.service";
import { FuseWindowScrollService } from "@fuse/services/fuse-window-scroll.service";

import { InfGlobalSvc } from "@infiot-fuse/services/inf-global.service";

@Component({
  selector: "vertical-layout-1",
  templateUrl: "./layout-1.component.html",
  styleUrls: ["./layout-1.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class VerticalLayout1Component implements OnInit, OnDestroy {
  fuseConfig: any;

  // Private
  private _unsubscribeAll: rxjs.Subject<any>;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _cdr: ChangeDetectorRef,
    public fuseWindowScrollService: FuseWindowScrollService,
    public infGlobalSvc: InfGlobalSvc,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new rxjs.Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._fuseConfigService.config.pipe(rxjsoperators.takeUntil(this._unsubscribeAll)).subscribe((config) => {
      this.fuseConfig = config;
      this._cdr.detectChanges();
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
