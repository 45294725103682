import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatRippleModule } from "@angular/material/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { FeatureLabelModule } from "@infiot/ngx-shared-lib";
import { InfIconModule } from "@infiot-fuse/components/icon/inf-icon.module";

import { FuseNavigationComponent } from "./navigation.component";
import { FuseNavVerticalItemComponent } from "./vertical/item/item.component";
import { FuseNavVerticalCollapsableComponent } from "./vertical/collapsable/collapsable.component";
import { FuseNavVerticalGroupComponent } from "./vertical/group/group.component";

@NgModule({
  imports: [
    CommonModule,
    FeatureLabelModule,
    RouterModule,
    MatIconModule,
    MatRippleModule,
    TranslateModule.forChild(),
    InfIconModule,
  ],
  exports: [FuseNavigationComponent],
  declarations: [
    FuseNavigationComponent,
    FuseNavVerticalGroupComponent,
    FuseNavVerticalItemComponent,
    FuseNavVerticalCollapsableComponent,
  ],
})
export class FuseNavigationModule {}
