import QueryString from "qs";

export function buildQueryForFuse(query: object): string {
  return QueryString.stringify(
    Object.fromEntries(Object.entries(query).map(([key, value]) => [key, value === undefined ? null : value])),
    {
      addQueryPrefix: true,
      strictNullHandling: true,
      arrayFormat: "indices",
      encoder: (str, defaultEncoder, _, type) => (type === "value" ? defaultEncoder(str) : str),
    },
  );
}

export function buildLinkToFuse(path: string, query: object) {
  const queryString = buildQueryForFuse(query);

  return `${path}${queryString}`;
}
