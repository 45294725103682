const ipToBinary = (ip: string) => {
  return ip
    .split(".")
    .map((octet) => parseInt(octet, 10).toString(2).padStart(8, "0"))
    .join("");
};

const applySubnetMask = (ip: string, mask: string) => {
  const binaryIp = ipToBinary(ip);
  const binaryMask = ipToBinary(mask);
  let networkAddress = "";
  for (let i = 0; i < 32; i++) {
    networkAddress += binaryIp[i] === "1" && binaryMask[i] === "1" ? "1" : "0";
  }

  return networkAddress;
};

export const checkIfHopIpFallsInLanPool = (hopIp: string, lanIp?: string, subnetMask?: string) => {
  if (!lanIp || !subnetMask) {
    return false;
  }
  const lanNetwork = applySubnetMask(lanIp, subnetMask);
  const hopNetwork = applySubnetMask(hopIp, subnetMask);

  return lanNetwork === hopNetwork;
};
