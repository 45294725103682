import * as infschemas from "@infiot/infschemas";
import { UntypedFormArray } from "@angular/forms";
import { Features } from "app/feature-flag/feature-flag.util";

export const HOP_SITE_KEY_FOR_SIG_IPSEC = "ipsec";
export const PBR_NEXT_HOP_SITE_CLOUD_HUB = "bwansse";
export const DestinationPtdType = infschemas.DestinationPtdType;
export interface Destination {
  ptd_type: infschemas.DestinationPtdType;
}

export interface HubDestination extends Destination {
  id: string;
  name: string;
  ip: string;
  groupId?: string;
  groupName?: string;
}

export interface NetskopeDestination extends Destination {
  id: string;
  netskope: {
    account_name: string;
    account_id: string;
    pops?: [];
  };
}

export interface SIGGreEndpoints {
  public_ipv4: string;
  probe_ipv4: string;
}

export interface SIGIPSecEndpoints {
  public_ipv4: string;
}

export interface SIGGreDestination extends Destination {
  id: string;
  sig_gre: {
    name: string;
    cfg: {
      gre_endpoints: SIGGreEndpoints[];
    };
  };
}

export interface SIGIPSecDestination extends Destination {
  id: string;
  sig_ipsec: {
    name: string;
    cfg: {
      ipsec_endpoints: SIGIPSecEndpoints[];
    };
  };
}

export interface MTHubDestination extends Destination {
  id: string;
  bwan_sse_hub: {
    name: string;
    method: infschemas.DestinationNewEdgeMethod;
    pops: string[];
  };
}

export interface TypeList {
  key: Destination["ptd_type"];
  // feature name if destination is enabled through a feature flag
  featureName?: Features;
}

export function getGenericIPSecName(entry: infschemas.DestinationConfigGenericIpsec) {
  let name: string | undefined;
  switch (entry.ptd_type) {
    case DestinationPtdType.NETSKOPE:
      name = entry.netskope ? entry.netskope.account_name : entry.id;
      break;

    case DestinationPtdType.SIG_GRE:
      name = entry.sig_gre ? entry.sig_gre.name : entry.id;
      break;

    case DestinationPtdType.SIG_IPSEC:
      name = entry.sig_ipsec ? entry.sig_ipsec.name : entry.id;
      break;

    case DestinationPtdType.NEWEDGE:
      if (entry.bwan_sse_hub) {
        name = entry.bwan_sse_hub.name;
        if (entry.bwan_sse_hub.pops?.length) {
          name = `${name} (${entry.bwan_sse_hub.pops.join(", ")})`;
        }
      } else {
        name = entry.id;
      }
      break;
  }

  return name;
}

export function getHubEntryIndex(name: string, hubDestinations: UntypedFormArray) {
  return hubDestinations.controls.findIndex(
    (form) => name === form.value.ovlay_name && form.value.ptd_type === DestinationPtdType.HUB,
  );
}

export function getHubGroupEntryIndex(name: string, hubDestinations: UntypedFormArray) {
  return hubDestinations.controls.findIndex(
    (form) => name === form.value.group_name && form.value.ptd_type === DestinationPtdType.HUB_GROUP,
  );
}

export function getIPSecIndex(id: string, ipsecDestinations: UntypedFormArray) {
  return ipsecDestinations.controls.findIndex((form) => id === form.value.id);
}

export function getHubDataForDisplay(hubList: infschemas.NexthopSettings[]) {
  return hubList.map((hub) => {
    const rr: HubDestination = {
      ptd_type: hub.group_id ? DestinationPtdType.HUB_GROUP : DestinationPtdType.HUB,
      id: hub.group_id ? hub.group_id : hub.ovlay_id,
      name: hub.group_id ? hub.group_name! : hub.ovlay_name!,
      ip: hub.ovlay_ipv4,
      groupId: hub.group_id ?? "",
      groupName: hub.group_name ?? "",
    };

    return rr;
  });
}

const HUB_DESTINATION_TYPE: TypeList = {
  key: DestinationPtdType.HUB,
};

export const HUB_GROUP_DESTINATION_TYPE: TypeList = {
  key: DestinationPtdType.HUB_GROUP,
};

export const ALL_DESTINATION_TYPES: TypeList[] = [
  HUB_DESTINATION_TYPE,
  {
    key: DestinationPtdType.NETSKOPE,
  },
  {
    key: DestinationPtdType.SIG_GRE,
    featureName: "POLICY_DESTINATION_SIG_GRE",
  },
  {
    key: DestinationPtdType.SIG_IPSEC,
  },
  {
    key: DestinationPtdType.NEWEDGE,
    featureName: "POLICY_DESTINATION_NEW_EDGE_CLOUD_HUB",
  },
];

export const CLIENT_DESTINATION_TYPES: TypeList[] = [
  HUB_DESTINATION_TYPE,
  {
    key: DestinationPtdType.HUB_GROUP,
  },
];
