import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";

import { FuseNavigationModule, FuseNavigationFooterModule } from "@fuse/components";
import { FuseSharedModule } from "@fuse/shared.module";

import { NavbarVerticalStyle2Component } from "app/layout/components/navbar/vertical/style-2/style-2.component";

@NgModule({
  declarations: [NavbarVerticalStyle2Component],
  imports: [
    MatButtonModule,
    MatIconModule,
    FuseSharedModule,
    FuseNavigationModule,
    FuseNavigationFooterModule,
    RouterModule,
  ],
  exports: [NavbarVerticalStyle2Component],
})
export class NavbarVerticalStyle2Module {}
