import * as infgqlsite from "@infiot-fuse/interfaces/mgmtsvc/site.types";

/**
 * Grouping site status
 * like site in which are status will be treated as Online, Offline, Pending etc.
 */
const status = infgqlsite.SiteCompoundStatus;
export const SITE_GROUPED_STATUS = new Map<string, Set<string>>([
  [status.UP, new Set([status.UP, status.STALE_CONFIG, status.NO_PATHS, status.NOT_REPORTING])],
  [status.DOWN, new Set([status.DOWN])],
  [status.PENDING, new Set([status.PENDING])],
]);
