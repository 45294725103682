<div fxLayout.gt-xs="row" fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="space-between">
  <!-- Nav element -->
  <inf-jump-to-navigation fxFlex="1 0 100%" fxFlex.gt-xs="1 0 24%" [items]="configurables"> </inf-jump-to-navigation>
  <div
    class="right-column"
    fxLayout="column"
    fxFlex="1 0 100%"
    fxFlex.gt-xs="1 1 74%"
    fxLayoutAlign="space-between"
    fxLayoutGap="{{ infGlobalSvc.gutterMd }}"
  >
    <ng-template infPolicyDetailsHost></ng-template>
  </div>
</div>
