<div id="main" [ngClass]="{ 'distraction-free': fuseConfig.distractionFree }">
  <div id="container-1" class="container">
    <fuse-sidebar
      name="navbar"
      class="navbar-fuse-sidebar"
      [folded]="fuseConfig.layout.navbar.folded"
      *ngIf="!fuseConfig.layout.navbar.hidden && !fuseConfig.distractionFree"
    >
      <navbar [variant]="fuseConfig.layout.navbar.variant" class="left-navbar"></navbar>
    </fuse-sidebar>
    <div
      id="container-2"
      class="container"
      fusePerfectScrollbar
      [fusePerfectScrollbarOptions]="{
        suppressScrollX: true,
        updateOnRouteChange: true,
        scrollYMarginOffset: 3,
        infOnScroll: fuseWindowScrollService.updateScroll
      }"
    >
      <!-- CONTENT -->
      <content class="main-gutter"></content>
      <!-- / CONTENT -->
    </div>
  </div>
</div>
