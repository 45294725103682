import { Injectable } from "@angular/core";
import { Loader } from "@googlemaps/js-api-loader";
import { InfSettingsSvc } from "@infiot-fuse/services/inf-settings.service";

/**
 * Loads google map scripts
 */
@Injectable()
export class InfGoogleMapProvider {
  public loader: Loader;

  public isMapLoaded = false;

  constructor(private _infSettingsSvc: InfSettingsSvc) {
    this._infSettingsSvc.getInfSettings().subscribe(async (settings) => {
      if (!this.loader) {
        this.loader = new Loader({ apiKey: settings.fes_mapsApiKey });
        await this.loader.importLibrary("maps");
        await google.maps.importLibrary("marker");
        this.isMapLoaded = true;
      }
    });
  }
}
