export const convertLogoToLightVariant = (logoUrl?: string) => {
  if (!logoUrl || logoUrl.indexOf("-dark") === -1) {
    return logoUrl;
  }

  return logoUrl.split("-dark").join("-light");
};

/**
 * Moves an item one index in an array to another.
 * @param fromIndex Starting index of the item.
 * @param toIndex Index to which the item should be moved.
 * @param array Array in which to move the item.
 * @returns T[] - Updated array.
 */

export function moveElementInArray<T>(fromIndex: number, toIndex: number, array: T[]): T[] {
  const element = array[fromIndex];
  array.splice(fromIndex, 1);
  array.splice(toIndex, 0, element);

  return array;
}
