import { Geocode, GeocodeInput } from "gqlv3/graphql";

export interface LocationOptions extends GeocodeInput {
  formattedAddress: string;
}

export function parseGooglePlacesToLocationOptions(data: { places: google.maps.places.Place[] }): LocationOptions[] {
  return data.places.map((value: google.maps.places.Place) => {
    return {
      formattedAddress: String(value.formattedAddress),
      latitude: value.location?.lat() || 0,
      longitude: value.location?.lng() || 0,
      googlePlaceId: value.id,
    };
  });
}

export function getFormattedAddress(location: Geocode): string {
  const formattedAddress: string[] = [];
  const { city, latitude, longitude, stateIso, countryIso } = location;

  if (city) {
    formattedAddress.push(city);

    if (stateIso) {
      formattedAddress.push(stateIso);
    }
    if (countryIso) {
      formattedAddress.push(countryIso);
    }
  } else {
    formattedAddress.push(latitude);
    formattedAddress.push(longitude);
  }

  return formattedAddress.join(", ");
}

export function getDisplayLocation(location: Geocode) {
  const { city, stateIso, countryIso } = location;

  if (city) {
    const formattedAddress = [city];
    if (stateIso) {
      formattedAddress.push(stateIso);
    }
    if (countryIso) {
      formattedAddress.push(countryIso);
    }

    return formattedAddress.length === 1 ? city : formattedAddress.join(", ");
  }

  return "";
}
