<mat-card id="{{ id }}">
  <inf-card-header [cardTitle]="title | translate" [icon]="icon"> </inf-card-header>
  <mat-card-content>
    <div fxLayout="column inline" fxLayoutAlign="end end" class="cos-grid">
      <div fxLayout="row" fxLayoutAlign="end start">
        <ng-container *ngFor="let priority of priorities">
          <div class="cell-width light-grey-bg" fxLayout="row" fxLayoutAlign="center center">
            {{ priority.label | translate }}
          </div>
        </ng-container>
      </div>
      <ng-container *ngFor="let trafficClass of trafficClasses">
        <div fxLayout="row" fxLayoutAlign="end start">
          <div class="cell-width category-cell-width light-grey-bg" fxLayout="row" fxLayoutAlign="center center">
            <inf-icon class="title-icon">svg:{{ trafficClass.icon }}</inf-icon>
            <span fxHide.lt-sm>{{ trafficClass.label | translate }}</span>
          </div>
          <div class="cell-width label-cell-width dark-grey-bg" fxLayout="row" fxLayoutAlign="center center">
            {{ "IDS_POLICIES_QOS_BANDWIDTH_TABLE_QUEUEING" | translate }}
          </div>
          <ng-container *ngFor="let priority of priorities">
            <div
              class="cell-width light-grey-bg"
              fxLayout="row"
              fxLayoutAlign="center center"
              [formGroup]="indexMappingForm[trafficClass.schemaClass][priority.schemaPriority]"
            >
              <mat-checkbox
                id="infQosBandwidthRealtime{{ trafficClass.id }}{{ priority.id }}"
                formControlName="cos_llq"
              >
              </mat-checkbox>
            </div>
          </ng-container>
        </div>
        <div fxLayout="row" fxLayoutAlign="end start">
          <div class="cell-width label-cell-width dark-grey-bg" fxLayout="row" fxLayoutAlign="center center">
            {{ "IDS_POLICIES_QOS_BANDWIDTH_TABLE_GUARANTEED" | translate }}
          </div>
          <ng-container *ngFor="let priority of priorities">
            <div
              class="cell-width light-grey-bg"
              fxLayout="row"
              fxLayoutAlign="center center"
              [formGroup]="indexMappingForm[trafficClass.schemaClass][priority.schemaPriority]"
            >
              <mat-form-field class="garantee-percent">
                <input
                  matInput
                  type="number"
                  min="0"
                  max="100"
                  id="infQosBandwidthGuaranteed{{ trafficClass.id }}{{ priority.id }}"
                  formControlName="cos_min_guarantee_bw_percent"
                  class="center-text-input"
                />
                <mat-error infError="cos_min_guarantee_bw_percent"></mat-error>
              </mat-form-field>
            </div>
          </ng-container>
        </div>
        <div fxLayout="row" fxLayoutAlign="end start">
          <div class="cell-width label-cell-width dark-grey-bg" fxLayout="row" fxLayoutAlign="center center">
            {{ "IDS_POLICIES_QOS_BANDWIDTH_TABLE_LAST_RESORT" | translate }}
          </div>
          <ng-container *ngFor="let priority of priorities">
            <div
              class="cell-width light-grey-bg"
              fxLayout="row"
              fxLayoutAlign="center center"
              [formGroup]="indexMappingForm[trafficClass.schemaClass][priority.schemaPriority]"
            >
              <mat-checkbox
                id="infQosBandwidthLastResort{{ trafficClass.id }}{{ priority.id }}"
                formControlName="cos_last_resort"
              >
              </mat-checkbox>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </mat-card-content>
</mat-card>
