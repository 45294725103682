import inftslng from "@infiot/inftslng";
import type { RequireExactlyOne } from "type-fest";

export interface DiffChangeWithSensitiveField extends inftslng.InfDiffChange {
  isSensitive?: boolean;
}

export type SensitivePath = RequireExactlyOne<
  {
    path: string;
    regex: RegExp;
  },
  "path" | "regex"
>;

const sensitivePaths: Array<SensitivePath> = [
  {
    path: "wifi_encryption.wencr_key",
  },
  {
    path: "dcfg_general.dcfg_ssh_password",
  },
  {
    path: "dcfg_general.dcfg_localui_password",
  },
  {
    path: "dcfg_general.dcfg_localadmin_wifi_psk",
  },
  {
    regex: /dcfg_virtual_networks\.[0-9]+\.vnet_ipsec_tunnels.[0-9]+\.psk/i,
  },
];

function defaultCheckIfSensitive(path: string, sensitiveFields: SensitivePath[]) {
  let isSensitive = false;
  for (let i = 0; i < sensitiveFields.length; i++) {
    const pathInLower = path.toLowerCase();
    const currentSensitiveField = sensitiveFields[i];
    isSensitive = currentSensitiveField.regex
      ? currentSensitiveField.regex.test(pathInLower)
      : pathInLower.includes(currentSensitiveField.path);

    if (isSensitive) {
      break;
    }
  }

  return isSensitive;
}

export default function markChangesSensitive(
  changes: DiffChangeWithSensitiveField[],
  sensitiveFields: Array<SensitivePath> = sensitivePaths,
  checkIfSensitive?: (path: string, sensitiveFields: SensitivePath[]) => boolean,
) {
  if (changes === undefined || !Array.isArray(changes)) {
    return changes;
  }
  const checkSensitiveCb = checkIfSensitive || defaultCheckIfSensitive;

  return changes.map((change) => {
    change.isSensitive = false;
    if (checkSensitiveCb(change.path, sensitiveFields)) {
      change.isSensitive = true;
    }

    return change;
  });
}
