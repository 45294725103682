import { Component, AfterViewInit, Input, ElementRef, ViewChild, OnDestroy } from "@angular/core";

interface Window {
  bwanMicroReact?: {
    mount: (component: any, props: object, element: HTMLElement) => { unmount(): void };
    [key: string]: any;
  };
}

@Component({
  selector: "app-react-wrapper",
  template: `<div class="micro" #reactRef></div>`,
})
export class ReactWrapperComponent implements AfterViewInit, OnDestroy {
  @ViewChild("reactRef") reactRef!: ElementRef<HTMLElement>;

  @Input("component") component: string;

  @Input("props") props: object;

  reactRoot?: { unmount: () => void };

  ngAfterViewInit(): void {
    const bwanMicroReact = (window as Window).bwanMicroReact;
    const mount = bwanMicroReact?.mount;
    if (!mount) {
      console.error("micro: mount is not available");

      return;
    }

    const component = bwanMicroReact[this.component];
    if (!mount) {
      console.error(`micro: component ${this.component} does not exist, has ${Object.keys(bwanMicroReact)}`);

      return;
    }

    this.reactRoot = mount(component, this.props, this.reactRef.nativeElement);
  }

  ngOnDestroy() {
    this.reactRoot?.unmount();
  }
}
