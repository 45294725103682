import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from "@angular/core";
import { Subject } from "rxjs";

import { FuseSidebarService } from "./sidebar.service";

@Component({
  selector: "fuse-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FuseSidebarComponent implements OnInit, OnDestroy {
  // Name
  @Input()
  name: string;

  // Key
  @Input()
  key: string;

  _foldedWidth: number;

  // Private
  private _folded: boolean;
  private _unsubscribeAll: Subject<any>;

  @HostBinding("class.animations-enabled")
  private _animationsEnabled: boolean;

  /**
   * Constructor
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {ElementRef} _elementRef
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {Renderer2} _renderer
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _elementRef: ElementRef,
    private _fuseSidebarService: FuseSidebarService,
    private _renderer: Renderer2,
  ) {
    // Set the defaults
    // Set the private defaults
    this._foldedWidth = 56;
    this._animationsEnabled = false;
    this._folded = false;
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Folded
   *
   * @param {boolean} value
   */
  @Input()
  set folded(value: boolean) {
    // Set the folded
    this._folded = value;

    // Programmatically add/remove padding to the element
    // that comes after or before based on the position
    let sibling;

    const styleValue = this._foldedWidth + "px";

    sibling = this._elementRef.nativeElement.nextElementSibling;

    // If there is no sibling, return...
    if (!sibling) {
      return;
    }

    // If folded...
    if (value) {
      // Fold the sidebar
      this.fold();

      // Set the folded width
      this._renderer.setStyle(this._elementRef.nativeElement, "width", styleValue);
      this._renderer.setStyle(this._elementRef.nativeElement, "min-width", styleValue);
      this._renderer.setStyle(this._elementRef.nativeElement, "max-width", styleValue);

      // Set the style and class
      this._renderer.addClass(this._elementRef.nativeElement, "folded");
    }
    // If unfolded...
    else {
      // Unfold the sidebar
      this.unfold();

      // Remove the folded width
      this._renderer.removeStyle(this._elementRef.nativeElement, "width");
      this._renderer.removeStyle(this._elementRef.nativeElement, "min-width");
      this._renderer.removeStyle(this._elementRef.nativeElement, "max-width");

      // Remove the style and class
      this._renderer.removeClass(this._elementRef.nativeElement, "folded");
    }
  }

  get folded(): boolean {
    return this._folded;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Register the sidebar
    this._fuseSidebarService.register(this);

    // Setup folded
    this._setupFolded();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // If the sidebar is folded, unfold it to revert modifications
    if (this.folded) {
      this.unfold();
    }

    // Unregister the sidebar
    this._fuseSidebarService.unregister();

    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setup the initial folded status
   *
   * @private
   */
  private _setupFolded(): void {
    // Return, if sidebar is not folded
    if (!this.folded) {
      return;
    }

    // Programmatically add/remove padding to the element
    // that comes after or before based on the position
    let sibling;

    const styleValue = this._foldedWidth + "px";

    // Get the sibling and set the style rule
    sibling = this._elementRef.nativeElement.nextElementSibling;

    // If there is no sibling, return...
    if (!sibling) {
      return;
    }

    // Fold the sidebar
    this.fold();

    // Set the folded width
    this._renderer.setStyle(this._elementRef.nativeElement, "width", styleValue);
    this._renderer.setStyle(this._elementRef.nativeElement, "min-width", styleValue);
    this._renderer.setStyle(this._elementRef.nativeElement, "max-width", styleValue);

    // Set the style and class
    this._renderer.addClass(this._elementRef.nativeElement, "folded");
  }

  /**
   * Enable the animations
   *
   * @private
   */
  private _enableAnimations(): void {
    // Return if animations already enabled
    if (this._animationsEnabled) {
      return;
    }

    // Enable the animations
    this._animationsEnabled = true;

    // Mark for check
    this._changeDetectorRef.markForCheck();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Fold the sidebar permanently
   */
  fold(): void {
    // Only work if the sidebar is not folded
    if (this.folded) {
      return;
    }

    // Enable the animations
    this._enableAnimations();

    // Fold
    this.folded = true;

    // Mark for check
    this._changeDetectorRef.markForCheck();
  }

  /**
   * Unfold the sidebar permanently
   */
  unfold(): void {
    // Only work if the sidebar is folded
    if (!this.folded) {
      return;
    }

    // Enable the animations
    this._enableAnimations();

    // Unfold
    this.folded = false;

    // Mark for check
    this._changeDetectorRef.markForCheck();
  }

  /**
   * Toggle the sidebar fold/unfold permanently
   */
  toggleFold(): void {
    if (this.folded) {
      this.unfold();
    } else {
      this.fold();
    }
  }
}
