import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { FuseSharedModule } from "@fuse/shared.module";
import { SelectInputModule } from "@infiot/ngx-shared-lib";
import { InfSegmentSelector } from "./segment-selector.component";

@NgModule({
  imports: [CommonModule, FuseSharedModule, SelectInputModule],
  exports: [InfSegmentSelector],
  declarations: [InfSegmentSelector],
  providers: [],
})
export class InfSegmentSelectorModule {}
