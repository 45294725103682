<div id="loggedout" fxLayout="column">
  <div id="loggedout-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
    <div
      id="loggedout-form"
      [@animate]="{ value: '*', params: { duration: '300ms', y: '100px' } }"
      *infLet="m_settings$ | async as settings"
    >
      <div class="logo">
        <img src="{{ convertLogo(settings.fes_effectiveSettings.stn_branding?.brnd_portal_logo) }}" />
      </div>

      <div class="title" style="color: black">
        Your <b>{{ settings.inf_tenant?.it_name }}</b> {{ settings.fes_effectiveSettings.stn_branding?.brnd_name
        }}<br />
        session has ended.
      </div>

      <div class="subtitle">Please click <a href="/">here</a> to login again.</div>
    </div>
  </div>
</div>
