import { Component, OnInit, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { PolicyDetailsService } from "@policy/policy-details/policy-details.service";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { InfGlobalSvc } from "@infiot-fuse/services/inf-global.service";

@Component({
  selector: "app-sdcard",
  templateUrl: "./sdcard.component.html",
})
export class InfPolicyDetailsSdcardComponent implements OnInit {
  @Input() data: any;

  form: UntypedFormGroup;

  formatDisabled = true;

  constructor(private _policyDetailsService: PolicyDetailsService, public infGlobalSvc: InfGlobalSvc) {
    this.form = this._policyDetailsService.getGeneralSettingsForm().get("pcfg_storage") as UntypedFormGroup;
    this.formatDisabled = !this.form.value.pcfg_storage_enable;
  }

  ngOnInit(): void {}

  toggleSdCard(change: MatSlideToggleChange) {
    this.formatDisabled = !change.checked;
  }

  get id() {
    return this.data ? this.data.id : "sdcard";
  }

  get icon() {
    return this.data.icon;
  }

  get title() {
    return this.data.title;
  }
}
