import { BehaviorSubject, Observable } from "rxjs";
import { Injectable } from "@angular/core";

import { FuseSidebarComponent } from "./sidebar.component";

@Injectable({
  providedIn: "root",
})
export class FuseSidebarService {
  // Private
  private _sidebar: FuseSidebarComponent | null = null;
  private _onSidebarOpenChange: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /**
   * Constructor
   */
  constructor() {}

  /**
   * Add the sidebar to the registry
   * @param sidebar
   */
  register(sidebar): void {
    this._sidebar = sidebar;
  }

  /**
   * Remove the sidebar from the registry
   */
  unregister(): void {
    // Unregister the sidebar
    this._sidebar = null;
  }

  /**
   * Return the sidebar with the given key
   * @returns {FuseSidebarComponent}
   */
  getSidebar(): FuseSidebarComponent | null {
    return this._sidebar;
  }

  get onSidebarOpenChange(): Observable<boolean> {
    return this._onSidebarOpenChange.asObservable();
  }

  toggleFold(): void {
    if (this._sidebar) {
      this._onSidebarOpenChange.next(!this._sidebar.folded);
      this._sidebar.toggleFold();
    }
  }
}
