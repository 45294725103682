import { Component, Input } from "@angular/core";

@Component({
  selector: "warning",
  templateUrl: "./warning.component.html",
  styleUrls: ["./warning.component.scss"],
})
export class VersionWarningComponent {
  @Input() versionText?: string;
}
